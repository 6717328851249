import { graphql, useStaticQuery } from "gatsby";
import React from "react";
import { FaYoutube } from "react-icons/fa";
import MediaCard from "./MediaCard";

import * as styles from "./Media.module.scss";

const Media = () => {
  const { sanityCompanyInfo: companyInfo, allSanityMedia } = useStaticQuery(graphql`
    query ArticleComponentQuery {
      sanityCompanyInfo {
        socialMediaLinks {
          youtube
        }
      }
      allSanityMedia(sort: { fields: date, order: DESC }) {
        nodes {
          _id
          title
          featuredImage {
            asset {
              gatsbyImageData(aspectRatio: 1.5)
            }
          }
          date
          url
        }
      }
    }
  `);

  const media = allSanityMedia.nodes;
  const goaYoutubeChannel = companyInfo.socialMediaLinks.youtube;

  return (
    <section id="goa-media" className={styles.media}>
      <h2>GOA Media</h2>
      <div>
        <div className={styles.mediaContainer}>
          {media.map(({ _id, featuredImage, date, title, url }) => (
            <MediaCard
              key={_id}
              featuredImage={featuredImage.asset}
              date={date}
              title={title}
              url={url}
            />
          ))}
        </div>
        <a
          href={goaYoutubeChannel}
          target="_blank"
          rel="noreferrer"
          className="btn-primary"
        >
          GOA Videos <FaYoutube />
        </a>
      </div>
    </section>
  );
};

export default Media;
