import React from 'react'
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { Navigation, Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
// import 'swiper/css';
// import 'swiper/css/navigation';
// import 'swiper/css/autoplay';

import * as styles from './Gallery.module.scss'
import { graphql, useStaticQuery } from 'gatsby';

const Gallery = () => {
  const { sanitySiteSettings: { gallery } } = useStaticQuery(graphql`
    query GalleryComponentQuery {
      sanitySiteSettings {
        gallery {
          asset {
            _id
            filename
            gatsbyImageData(height: 200)
          }
          credit
        }
      }
    }
  `)

  const galleryImageSlideElements = gallery.map((image) => (
    <SwiperSlide key={image.asset._id} className={styles.swiperSlide}>
      <GatsbyImage image={getImage(image.asset)} alt={`${image.asset.filename} | Image by ${image.credit}`} />
      <small className={styles.imageCredit}>{image.credit}</small>
    </SwiperSlide>
  ))

  return (
    <section id="gallery" className={styles.gallery}>
      <h2>GROW<span className='lower'>one</span>AFRICA&trade; Gallery</h2>
      <div className="my-swiper">
        <div className={`${styles.swiperButton} swiper-button-prev`} />
        <Swiper
          className={styles.swiper}
          modules={[Navigation, Autoplay]}
          spaceBetween={25}
          slidesPerView={"auto"}
          navigation={{
            prevEl: '.swiper-button-prev',
            nextEl: '.swiper-button-next'
          }}
          autoplay={{
            delay: 2500,
            disableOnInteraction: true,
          }}
          loop
        >
          {galleryImageSlideElements}
          {/* <SwiperSlide className={styles.swiperSlide}>
            <StaticImage 
              src="../../images/contact-page/gallery/joints-rolled-and-in-process.jpg"
              alt="joints-rolled-and-in-process" 
              layout="fixed"
              height={200}
            />
          </SwiperSlide>
          <SwiperSlide className={styles.swiperSlide}>
            <StaticImage 
              src="../../images/contact-page/gallery/bud-close-up-with-background.jpg"
              alt="bud-close-up-with-background" 
              layout="fixed"
              height={200}
            />
          </SwiperSlide>
          <SwiperSlide className={styles.swiperSlide}>
            <StaticImage 
              src="../../images/contact-page/gallery/bud-close-up.jpeg"
              alt="bud-close-up" 
              layout="fixed"
              height={200}
            />
          </SwiperSlide>
          <SwiperSlide className={styles.swiperSlide}>
            <StaticImage 
              src="../../images/contact-page/gallery/outdoor-grow-amongst-the-mountains.jpg"
              alt="outdoor-grow-amongst-the-mountains" 
              layout="fixed"
              height={200}
            />
          </SwiperSlide> */}
        </Swiper>
        <div className={`${styles.swiperButton} swiper-button-next`} />
      </div>
    </section>
  )
}

export default Gallery