import React from "react";
import { Link, graphql } from "gatsby";
import Media from "../components/Media";
import Gallery from "../components/Gallery";
import Footer from "../components/Layout/Footer";
import MediaCard from "../components/Media/MediaCard";

export default function MediaPage({ data }) {
  const newsletters = data.allSanityNewsletter.nodes;

  return (
    <>
      <main className="site-container" style={{ paddingTop: "2rem" }}>
        {/* Recent Newsletters */}
        <h1
          style={{
            marginBottom: "2rem",
            fontSize: "2rem",
            textAlign: "center",
            textTransform: "uppercase",
          }}
        >
          Newsletters
        </h1>
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "repeat(auto-fill, minmax(300px, 1fr))",
            gap: "4rem",
          }}
        >
          {newsletters.map(({ _id, featuredImage, publishDate, title, slug }) => (
            <MediaCard
              key={_id}
              featuredImage={featuredImage.asset}
              date={publishDate}
              dateOptions={{
                month: "long",
                year: "numeric",
              }}
              title={title}
              linkTo={`/newsletters/${slug.current}/`}
            />
          ))}
          <Link
            to="/newsletters/"
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              border: "var(--site-border)",
            }}
          >
            View all newsletters
          </Link>
        </div>
        {/* GOA MEDIA */}
        <Media />
      </main>
      <Footer>
        <Gallery />
      </Footer>
    </>
  );
}

export const query = graphql`
  query MediaPageQuery {
    allSanityNewsletter(sort: { fields: publishDate, order: DESC }, limit: 5) {
      nodes {
        _id
        title
        slug {
          current
        }
        publishDate
        featuredImage {
          asset {
            gatsbyImageData(aspectRatio: 1.5)
          }
        }
      }
    }
  }
`;
